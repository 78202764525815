
import React, { useEffect } from 'react';

const Sitemap = () => {
  useEffect(() => {
    const sitemapXML = `<?xml version="1.0" encoding="UTF-8"?>
<urlset
      xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
      xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
      xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
<!-- created with Free Online Sitemap Generator www.xml-sitemaps.com -->


<url>
  <loc>https://www.placestovisitindia.com/</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>1.00</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/manali-to-leh-bike-trip</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/bike-route-spiti-valley-Himachal-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kashipur-niti-bike-route</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/destination-wedding-in-jaipur</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/khatu-shyam-ji-sikar-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/golden-temple-amritsar-punjab</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/sri-venkateswara-swamy-tirupati-balaji</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/dwarka-gujarat</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/ayodhya-uttar-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kainchi-dham-neem-karauli-ashram-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/mata-vaishno-devi-dham-jammu</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/ghodakhal-bell-temple-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/blogs</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/trekking</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/heritage</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/pilgrimages</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/adventure</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/spirituality</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/beaches-and-more</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/about</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/contact</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/chopta-tunganath-chandrashila</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/chipla-kedar-trek-kumaon-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/panchachuli-base-camp-trek-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kartik-swami-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kedarkantha-garhwal-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/valley-of-flowers-trek-garhwal-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/hemkund-sahib-garhwal-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/vyas-kund-himachal-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/hampta-pass-himachal-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/moon-peak-himachal-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/triund-mcleodganj-dharmshala-himachal-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/seoj-dhar-kailash-kund-jammu</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kashmir-great-lakes</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/thajiwas-glacier-trek</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/tarsar-marsar-lake-trek-kashmir</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/tulian-lake-trek-kashmir</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/chadar-trek-ladakh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/lasermo-la-trek-ladakh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/markha-valley-trek-ladakh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/rumtse-to-tso-moriri-trek-ladakh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/sham-valley-trek-ladakh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/anini-seven-lakes-trek-arunachal-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/mawryngkhang-bamboo-trek</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/sandakphu-phalut-west-bengal</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kalavantin-durg-trek-mumbai-maharashtra</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kalsubai-peak-maharashtra</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/netravati-peak-trek-kudremukh-karnataka</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/adventure-in-rishikesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/rann-of-kachchh-gujarat</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/adi-kailash-aum-parvat</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/cherrapunji-meghalaya</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/rameshwaram-tamilnadu</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/spirituality-rishikesh-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kashi-aka-varanasi-uttarpradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/gokarna-karnataka</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/sandhan-valley-maharashtra</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/adventure-in-coorg</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/chembra-peak-trek-wayanad-kerala</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/anamudi-peak-trek-munnar-kerala</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/beach-city-goa</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/adventure-in-goa</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kumbhalgarh-fort-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/pinkcity-jaipur-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/hampi-karnataka</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/jagannath-puri-dham-odisha</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/tajmahal-agra-uttar-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/mahabodhi-temple-bodhgaya-bihar</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/rani-ki-vav-patan-gujarat</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/ruins-of-nalanda-mahavihara-bihar</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/teerthraj-pushkar-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/amer-fort-jaipur-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/golden-fort-sonar-kila-jaisalmer-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/chand-baori-abhaneri-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/hadi-rani-ka-kund-todaraisingh-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/statue-of-unity-vadodara-gujarat</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/khajuraho-madhya-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/elephanta-caves-mumbai</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/sanchi-stupa-madhya-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/dhamek-stupa-sarnath-varanasi-uttar-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/ajanta-ellora-caves-maharashtra</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/konark-sun-temple-odisha</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/jamiul-futuh-kozhikode-kerala</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/dargah-sharif-ajmer-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/mindrolling-monastery-dehradun-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/st-philomenas-church-mysuru-karnataka</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/adventure-in-gulmarg-kashmir</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/mahakaleshwar-jyotirlinga-ujjain-madhya-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kedarnath-garhwal-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/jawala-devi-kangra-himachal-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/chamundeshwari-devi-mysore-karnataka</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/ranakpur-jain-temple-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/palitana-jain-tirth-bhavnagar-gujarat</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/hemis-monastery-leh-ladakh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/tashiding-monastery-sikkim</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/aamby-valley-city-maharashtra</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/sri-bangla-sahib-delhi</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/haji-ali-dargah-mumbai-maharashtra</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/lakshadweep-india</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/sam-sand-dunes-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/cold-desert-nubra-valley-ladakh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/dalhousie-chamba-himachal-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/mechuka-arunachal-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/vijaynagar-arunachal-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/sural-bhatori-pangi-valley</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/lambasingi-andhra-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/dhordo-rann-of-kachchh-gujarat</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/raghurajpur-craft-village</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kullu-manali-himachal-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/mahabaleshwar-maharashtra</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/darma-valley-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/art-of-living-banglore</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/bungee-jumping-in-rishikesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/bungee-jumping-in-lonavala</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/dadu-temple-dudu-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/adventure-in-dandeli-karnataka</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/dhamma-bodhi-vipassana-centre-bodh-gaya-bihar</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/dhamma-giri-vipassana-centre-igatpuri-maharashtra</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/dhamma-pattana-vipassana-centre-mumbai</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/dol-ashram-almora-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/gorakhnath-math-gorakhpur-uttar-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/guru-ravidas-mandir-seer-goverdhanpur-varanasi</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/isha-foundation-coimbatore-tamilnadu</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/jim-corbett-national-park-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kabir-chaura-nath-varanasi</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kalindi-khal-expedition-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kaziranga-national-park</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/j-krishnamurti-foundation-study-centre-chennai</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/mountain-biking-in-leh-ladakh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/river-rafting-in-manali</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/mountain-biking-tehri-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/nanda-devi-east-base-camp-trek-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/ramana-maharshi-ashram-tiruvannamalai-tamilnadu</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/ranthambhore-national-park-rajasthan</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/rishi-valley-school-andhra-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/river-rafting-in-ganges-rishikesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/satsang-foundation-banglore</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/scuba-diving-in-andaman-nicobar-islands</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/scuba-diving-in-lakshadweep</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/shri-govardhan-math-puri-odisha</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/the-valley-school-banglore-karnataka</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/the-yoga-institute-mumbai</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/jeeyar-math-rishikesh-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/yogada-math-ranchi-bihar</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/yogada-math-noida-uttar-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/yogada-satsang-kolkata-west-bengal</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/skiing-in-auli</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/skiing-in-gulmarg</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/parmarth-niketan-ashram-rishikesh-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/acharya-prashant</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/gurudev-sri-sri-ravishankar</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/jaggi-vasudev-sadhguru</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/sri-madhukar-nath</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/bhrigu-lake-trek-himachal-pradesh</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/munnar-kerala</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/bandhavgarh-national-park</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/gir-national-park-gujarat</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/periyar-national-park-kerala</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/hemis-national-park</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/sundarban-national-park-west-bengal</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/auli-joshimath-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/best-places-to-visit-kerala</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/brahmatal-trek-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/dayara-bugyal-trek-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/har-ki-dun-trek-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/kuari-pass-pangarchulla-trek-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/rajasthan-best-places-to-visit</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/darjeeling-north-east-india</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/pindari-glacier-trek-uttarakhand</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/art-and-culture-india</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/festivals-in-india</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/food-culture-in-india</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/colorful-traditions-in-india</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/religions-and-faiths-in-india</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>
<url>
  <loc>https://www.placestovisitindia.com/science-and-technology-india</loc>
  <lastmod>2024-12-17T19:00:39+00:00</lastmod>
  <priority>0.80</priority>
</url>


</urlset>`;



    const blob = new Blob([sitemapXML], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    window.location.href = url;

    return () => URL.revokeObjectURL(url);
  }, []);

  return null;
};

export default Sitemap;
