export const customData = [
    {
        "type": "static",
        "url": "about"
    },
    {
        "type": "static",
        "url": "contact"
    },
    {
        "type": "post",
        "url": "chopta-tunganath-chandrashila",
        "last_updated": "2024-11-25T08:52:23+00:00"
    },
    {
        "type": "post",
        "url": "chipla-kedar-trek-kumaon-uttarakhand",
        "last_updated": "2024-10-29T11:10:37+00:00"
    },
    {
        "type": "post",
        "url": "panchachuli-base-camp-trek-uttarakhand",
        "last_updated": "2024-12-14T09:08:27+00:00"
    },
    {
        "type": "post",
        "url": "kartik-swami-uttarakhand",
        "last_updated": "2024-12-07T06:21:29+00:00"
    },
    {
        "type": "post",
        "url": "kedarkantha-garhwal-uttarakhand",
        "last_updated": "2024-11-25T09:08:08+00:00"
    },
    {
        "type": "post",
        "url": "valley-of-flowers-trek-garhwal-uttarakhand",
        "last_updated": "2024-11-05T07:22:59+00:00"
    },
    {
        "type": "post",
        "url": "hemkund-sahib-garhwal-uttarakhand",
        "last_updated": "2024-11-05T08:27:56+00:00"
    },
    {
        "type": "post",
        "url": "vyas-kund-himachal-pradesh",
        "last_updated": "2024-12-07T06:19:07+00:00"
    },
    {
        "type": "post",
        "url": "hampta-pass-himachal-pradesh",
        "last_updated": "2024-11-07T09:37:31+00:00"
    },
    {
        "type": "post",
        "url": "moon-peak-himachal-pradesh",
        "last_updated": "2024-11-07T09:51:38+00:00"
    },
    {
        "type": "post",
        "url": "triund-mcleodganj-dharmshala-himachal-pradesh",
        "last_updated": "2024-05-15T07:57:36+00:00"
    },
    {
        "type": "post",
        "url": "seoj-dhar-kailash-kund-jammu",
        "last_updated": "2024-11-19T04:35:44+00:00"
    },
    {
        "type": "post",
        "url": "kashmir-great-lakes",
        "last_updated": "2024-05-15T10:06:33+00:00"
    },
    {
        "type": "post",
        "url": "thajiwas-glacier-trek",
        "last_updated": "2024-05-15T10:26:34+00:00"
    },
    {
        "type": "post",
        "url": "tarsar-marsar-lake-trek-kashmir",
        "last_updated": "2024-05-15T10:43:02+00:00"
    },
    {
        "type": "post",
        "url": "tulian-lake-trek-kashmir",
        "last_updated": "2024-05-15T11:29:01+00:00"
    },
    {
        "type": "post",
        "url": "chadar-trek-ladakh",
        "last_updated": "2024-11-27T05:44:59+00:00"
    },
    {
        "type": "post",
        "url": "lasermo-la-trek-ladakh",
        "last_updated": "2024-05-15T12:33:56+00:00"
    },
    {
        "type": "post",
        "url": "markha-valley-trek-ladakh",
        "last_updated": "2024-05-15T16:22:05+00:00"
    },
    {
        "type": "post",
        "url": "rumtse-to-tso-moriri-trek-ladakh",
        "last_updated": "2024-05-16T04:25:28+00:00"
    },
    {
        "type": "post",
        "url": "sham-valley-trek-ladakh",
        "last_updated": "2024-06-02T04:11:16+00:00"
    },
    {
        "type": "post",
        "url": "anini-seven-lakes-trek-arunachal-pradesh",
        "last_updated": "2024-05-16T05:13:08+00:00"
    },
    {
        "type": "post",
        "url": "mawryngkhang-bamboo-trek",
        "last_updated": "2024-05-16T07:10:18+00:00"
    },
    {
        "type": "post",
        "url": "sandakphu-phalut-west-bengal",
        "last_updated": "2024-05-16T09:26:12+00:00"
    },
    {
        "type": "post",
        "url": "kalavantin-durg-trek-mumbai-maharashtra",
        "last_updated": "2024-05-16T09:52:57+00:00"
    },
    {
        "type": "post",
        "url": "kalsubai-peak-maharashtra",
        "last_updated": "2024-06-05T03:55:55+00:00"
    },
    {
        "type": "post",
        "url": "netravati-peak-trek-kudremukh-karnataka",
        "last_updated": "2024-12-11T08:41:22+00:00"
    },
    {
        "type": "post",
        "url": "adventure-in-rishikesh",
        "last_updated": "2024-09-26T12:10:22+00:00"
    },
    {
        "type": "post",
        "url": "manali-to-leh-bike-trip",
        "last_updated": "2024-11-21T08:25:04+00:00"
    },
    {
        "type": "post",
        "url": "bike-route-spiti-valley-Himachal-pradesh",
        "last_updated": "2024-05-18T06:44:58+00:00"
    },
    {
        "type": "post",
        "url": "dwarka-gujarat",
        "last_updated": "2024-12-02T06:19:38+00:00"
    },
    {
        "type": "post",
        "url": "rann-of-kachchh-gujarat",
        "last_updated": "2024-09-26T08:52:06+00:00"
    },
    {
        "type": "post",
        "url": "adi-kailash-aum-parvat",
        "last_updated": "2024-05-17T09:58:19+00:00"
    },
    {
        "type": "post",
        "url": "cherrapunji-meghalaya",
        "last_updated": "2024-12-04T06:28:52+00:00"
    },
    {
        "type": "post",
        "url": "rameshwaram-tamilnadu",
        "last_updated": "2024-05-17T13:23:29+00:00"
    },
    {
        "type": "post",
        "url": "spirituality-rishikesh-uttarakhand",
        "last_updated": "2024-05-18T09:07:56+00:00"
    },
    {
        "type": "post",
        "url": "kashi-aka-varanasi-uttarpradesh",
        "last_updated": "2024-12-09T09:10:07+00:00"
    },
    {
        "type": "post",
        "url": "destination-wedding-in-jaipur",
        "last_updated": "2024-05-18T11:18:51+00:00"
    },
    {
        "type": "post",
        "url": "gokarna-karnataka",
        "last_updated": "2024-05-18T13:21:48+00:00"
    },
    {
        "type": "post",
        "url": "sandhan-valley-maharashtra",
        "last_updated": "2024-05-24T13:32:26+00:00"
    },
    {
        "type": "post",
        "url": "adventure-in-coorg",
        "last_updated": "2024-09-26T12:10:53+00:00"
    },
    {
        "type": "post",
        "url": "ayodhya-uttar-pradesh",
        "last_updated": "2024-09-26T12:00:10+00:00"
    },
    {
        "type": "post",
        "url": "chembra-peak-trek-wayanad-kerala",
        "last_updated": "2024-06-02T03:45:55+00:00"
    },
    {
        "type": "post",
        "url": "khatu-shyam-ji-sikar-rajasthan",
        "last_updated": "2024-12-03T10:00:36+00:00"
    },
    {
        "type": "post",
        "url": "anamudi-peak-trek-munnar-kerala",
        "last_updated": "2024-06-02T03:50:16+00:00"
    },
    {
        "type": "post",
        "url": "golden-temple-amritsar-punjab",
        "last_updated": "2024-06-01T04:58:10+00:00"
    },
    {
        "type": "post",
        "url": "kashipur-niti-bike-route",
        "last_updated": "2024-06-01T12:01:04+00:00"
    },
    {
        "type": "post",
        "url": "sri-venkateswara-swamy-tirupati-balaji",
        "last_updated": "2024-08-24T09:51:56+00:00"
    },
    {
        "type": "post",
        "url": "beach-city-goa",
        "last_updated": "2024-09-26T08:56:27+00:00"
    },
    {
        "type": "post",
        "url": "adventure-in-goa",
        "last_updated": "2024-12-11T08:42:40+00:00"
    },
    {
        "type": "post",
        "url": "kumbhalgarh-fort-rajasthan",
        "last_updated": "2024-09-26T10:47:36+00:00"
    },
    {
        "type": "post",
        "url": "pinkcity-jaipur-rajasthan",
        "last_updated": "2024-12-14T07:25:30+00:00"
    },
    {
        "type": "post",
        "url": "hampi-karnataka",
        "last_updated": "2024-09-26T10:47:04+00:00"
    },
    {
        "type": "post",
        "url": "jagannath-puri-dham-odisha",
        "last_updated": "2024-06-18T11:22:28+00:00"
    },
    {
        "type": "post",
        "url": "tajmahal-agra-uttar-pradesh",
        "last_updated": "2024-09-26T10:36:39+00:00"
    },
    {
        "type": "post",
        "url": "mahabodhi-temple-bodhgaya-bihar",
        "last_updated": "2024-09-26T10:46:34+00:00"
    },
    {
        "type": "post",
        "url": "rani-ki-vav-patan-gujarat",
        "last_updated": "2024-12-02T06:09:29+00:00"
    },
    {
        "type": "post",
        "url": "ruins-of-nalanda-mahavihara-bihar",
        "last_updated": "2024-06-25T15:12:05+00:00"
    },
    {
        "type": "post",
        "url": "teerthraj-pushkar-rajasthan",
        "last_updated": "2024-12-03T09:51:19+00:00"
    },
    {
        "type": "post",
        "url": "amer-fort-jaipur-rajasthan",
        "last_updated": "2024-12-03T09:17:08+00:00"
    },
    {
        "type": "post",
        "url": "golden-fort-sonar-kila-jaisalmer-rajasthan",
        "last_updated": "2024-12-03T08:59:11+00:00"
    },
    {
        "type": "post",
        "url": "chand-baori-abhaneri-rajasthan",
        "last_updated": "2024-12-03T08:46:28+00:00"
    },
    {
        "type": "post",
        "url": "hadi-rani-ka-kund-todaraisingh-rajasthan",
        "last_updated": "2024-12-03T08:35:01+00:00"
    },
    {
        "type": "post",
        "url": "statue-of-unity-vadodara-gujarat",
        "last_updated": "2024-12-02T06:03:52+00:00"
    },
    {
        "type": "post",
        "url": "khajuraho-madhya-pradesh",
        "last_updated": "2024-07-08T10:19:42+00:00"
    },
    {
        "type": "post",
        "url": "elephanta-caves-mumbai",
        "last_updated": "2024-09-26T10:46:04+00:00"
    },
    {
        "type": "post",
        "url": "sanchi-stupa-madhya-pradesh",
        "last_updated": "2024-07-11T11:17:27+00:00"
    },
    {
        "type": "post",
        "url": "dhamek-stupa-sarnath-varanasi-uttar-pradesh",
        "last_updated": "2024-07-12T16:27:24+00:00"
    },
    {
        "type": "post",
        "url": "ajanta-ellora-caves-maharashtra",
        "last_updated": "2024-07-15T10:29:12+00:00"
    },
    {
        "type": "post",
        "url": "konark-sun-temple-odisha",
        "last_updated": "2024-07-16T12:32:07+00:00"
    },
    {
        "type": "post",
        "url": "jamiul-futuh-kozhikode-kerala",
        "last_updated": "2024-09-26T11:03:50+00:00"
    },
    {
        "type": "post",
        "url": "dargah-sharif-ajmer-rajasthan",
        "last_updated": "2024-12-03T04:56:36+00:00"
    },
    {
        "type": "post",
        "url": "mindrolling-monastery-dehradun-uttarakhand",
        "last_updated": "2024-07-23T16:15:33+00:00"
    },
    {
        "type": "post",
        "url": "st-philomenas-church-mysuru-karnataka",
        "last_updated": "2024-07-25T13:54:34+00:00"
    },
    {
        "type": "post",
        "url": "mata-vaishno-devi-dham-jammu",
        "last_updated": "2024-09-26T11:03:19+00:00"
    },
    {
        "type": "post",
        "url": "adventure-in-gulmarg-kashmir",
        "last_updated": "2024-09-26T12:17:47+00:00"
    },
    {
        "type": "post",
        "url": "mahakaleshwar-jyotirlinga-ujjain-madhya-pradesh",
        "last_updated": "2024-08-01T09:23:53+00:00"
    },
    {
        "type": "post",
        "url": "kedarnath-garhwal-uttarakhand",
        "last_updated": "2024-08-04T15:58:08+00:00"
    },
    {
        "type": "post",
        "url": "jawala-devi-kangra-himachal-pradesh",
        "last_updated": "2024-08-06T15:04:49+00:00"
    },
    {
        "type": "post",
        "url": "chamundeshwari-devi-mysore-karnataka",
        "last_updated": "2024-08-07T11:45:53+00:00"
    },
    {
        "type": "post",
        "url": "ranakpur-jain-temple-rajasthan",
        "last_updated": "2024-12-03T04:55:22+00:00"
    },
    {
        "type": "post",
        "url": "palitana-jain-tirth-bhavnagar-gujarat",
        "last_updated": "2024-12-02T05:59:52+00:00"
    },
    {
        "type": "post",
        "url": "hemis-monastery-leh-ladakh",
        "last_updated": "2024-08-13T10:42:48+00:00"
    },
    {
        "type": "post",
        "url": "tashiding-monastery-sikkim",
        "last_updated": "2024-08-15T16:10:40+00:00"
    },
    {
        "type": "post",
        "url": "aamby-valley-city-maharashtra",
        "last_updated": "2024-09-26T12:19:40+00:00"
    },
    {
        "type": "post",
        "url": "sri-bangla-sahib-delhi",
        "last_updated": "2024-08-21T10:41:11+00:00"
    },
    {
        "type": "post",
        "url": "haji-ali-dargah-mumbai-maharashtra",
        "last_updated": "2024-08-23T05:49:04+00:00"
    },
    {
        "type": "post",
        "url": "lakshadweep-india",
        "last_updated": "2024-08-27T10:54:03+00:00"
    },
    {
        "type": "post",
        "url": "sam-sand-dunes-rajasthan",
        "last_updated": "2024-12-03T04:54:22+00:00"
    },
    {
        "type": "post",
        "url": "cold-desert-nubra-valley-ladakh",
        "last_updated": "2024-08-31T13:24:34+00:00"
    },
    {
        "type": "post",
        "url": "dalhousie-chamba-himachal-pradesh",
        "last_updated": "2024-11-20T06:34:08+00:00"
    },
    {
        "type": "post",
        "url": "mechuka-arunachal-pradesh",
        "last_updated": "2024-09-05T07:34:07+00:00"
    },
    {
        "type": "post",
        "url": "vijaynagar-arunachal-pradesh",
        "last_updated": "2024-09-06T16:10:40+00:00"
    },
    {
        "type": "post",
        "url": "sural-bhatori-pangi-valley",
        "last_updated": "2024-09-09T13:24:44+00:00"
    },
    {
        "type": "post",
        "url": "lambasingi-andhra-pradesh",
        "last_updated": "2024-10-04T08:35:40+00:00"
    },
    {
        "type": "post",
        "url": "dhordo-rann-of-kachchh-gujarat",
        "last_updated": "2024-12-02T05:57:08+00:00"
    },
    {
        "type": "post",
        "url": "raghurajpur-craft-village",
        "last_updated": "2024-09-14T06:42:06+00:00"
    },
    {
        "type": "post",
        "url": "kullu-manali-himachal-pradesh",
        "last_updated": "2024-09-15T16:28:37+00:00"
    },
    {
        "type": "post",
        "url": "mahabaleshwar-maharashtra",
        "last_updated": "2024-09-20T03:47:06+00:00"
    },
    {
        "type": "post",
        "url": "darma-valley-uttarakhand",
        "last_updated": "2024-09-20T15:56:29+00:00"
    },
    {
        "type": "post",
        "url": "art-of-living-banglore",
        "last_updated": "2024-09-27T04:55:50+00:00"
    },
    {
        "type": "post",
        "url": "bungee-jumping-in-rishikesh",
        "last_updated": "2024-09-26T12:26:05+00:00"
    },
    {
        "type": "post",
        "url": "bungee-jumping-in-lonavala",
        "last_updated": "2024-09-23T12:42:01+00:00"
    },
    {
        "type": "post",
        "url": "dadu-temple-dudu-rajasthan",
        "last_updated": "2024-09-24T04:08:29+00:00"
    },
    {
        "type": "post",
        "url": "adventure-in-dandeli-karnataka",
        "last_updated": "2024-09-26T12:24:35+00:00"
    },
    {
        "type": "post",
        "url": "dhamma-bodhi-vipassana-centre-bodh-gaya-bihar",
        "last_updated": "2024-09-24T05:00:31+00:00"
    },
    {
        "type": "post",
        "url": "dhamma-giri-vipassana-centre-igatpuri-maharashtra",
        "last_updated": "2024-10-04T07:08:56+00:00"
    },
    {
        "type": "post",
        "url": "dhamma-pattana-vipassana-centre-mumbai",
        "last_updated": "2024-10-04T07:08:33+00:00"
    },
    {
        "type": "post",
        "url": "dol-ashram-almora-uttarakhand",
        "last_updated": "2024-10-27T05:56:07+00:00"
    },
    {
        "type": "post",
        "url": "gorakhnath-math-gorakhpur-uttar-pradesh",
        "last_updated": "2024-09-27T04:57:00+00:00"
    },
    {
        "type": "post",
        "url": "guru-ravidas-mandir-seer-goverdhanpur-varanasi",
        "last_updated": "2024-09-24T05:13:31+00:00"
    },
    {
        "type": "post",
        "url": "isha-foundation-coimbatore-tamilnadu",
        "last_updated": "2024-12-12T07:42:48+00:00"
    },
    {
        "type": "post",
        "url": "jim-corbett-national-park-uttarakhand",
        "last_updated": "2024-09-24T05:17:10+00:00"
    },
    {
        "type": "post",
        "url": "kabir-chaura-nath-varanasi",
        "last_updated": "2024-09-24T05:25:49+00:00"
    },
    {
        "type": "post",
        "url": "kalindi-khal-expedition-uttarakhand",
        "last_updated": "2024-10-03T17:15:21+00:00"
    },
    {
        "type": "post",
        "url": "kaziranga-national-park",
        "last_updated": "2024-09-24T05:50:19+00:00"
    },
    {
        "type": "post",
        "url": "j-krishnamurti-foundation-study-centre-chennai",
        "last_updated": "2024-09-27T05:00:26+00:00"
    },
    {
        "type": "post",
        "url": "mountain-biking-in-leh-ladakh",
        "last_updated": "2024-09-24T05:53:51+00:00"
    },
    {
        "type": "post",
        "url": "river-rafting-in-manali",
        "last_updated": "2024-09-24T05:57:04+00:00"
    },
    {
        "type": "post",
        "url": "mountain-biking-tehri-uttarakhand",
        "last_updated": "2024-09-24T06:00:22+00:00"
    },
    {
        "type": "post",
        "url": "nanda-devi-east-base-camp-trek-uttarakhand",
        "last_updated": "2024-09-24T06:02:02+00:00"
    },
    {
        "type": "post",
        "url": "ramana-maharshi-ashram-tiruvannamalai-tamilnadu",
        "last_updated": "2024-09-27T04:48:30+00:00"
    },
    {
        "type": "post",
        "url": "ranthambhore-national-park-rajasthan",
        "last_updated": "2024-09-24T06:06:26+00:00"
    },
    {
        "type": "post",
        "url": "rishi-valley-school-andhra-pradesh",
        "last_updated": "2024-09-24T06:20:17+00:00"
    },
    {
        "type": "post",
        "url": "river-rafting-in-ganges-rishikesh",
        "last_updated": "2024-09-24T06:22:08+00:00"
    },
    {
        "type": "post",
        "url": "satsang-foundation-banglore",
        "last_updated": "2024-09-24T06:23:52+00:00"
    },
    {
        "type": "post",
        "url": "scuba-diving-in-andaman-nicobar-islands",
        "last_updated": "2024-09-24T06:25:27+00:00"
    },
    {
        "type": "post",
        "url": "scuba-diving-in-lakshadweep",
        "last_updated": "2024-09-24T06:27:03+00:00"
    },
    {
        "type": "post",
        "url": "shri-govardhan-math-puri-odisha",
        "last_updated": "2024-09-24T06:28:53+00:00"
    },
    {
        "type": "post",
        "url": "the-valley-school-banglore-karnataka",
        "last_updated": "2024-09-24T06:30:42+00:00"
    },
    {
        "type": "post",
        "url": "the-yoga-institute-mumbai",
        "last_updated": "2024-09-24T06:32:10+00:00"
    },
    {
        "type": "post",
        "url": "jeeyar-math-rishikesh-uttarakhand",
        "last_updated": "2024-09-27T04:51:58+00:00"
    },
    {
        "type": "post",
        "url": "yogada-math-ranchi-bihar",
        "last_updated": "2024-09-24T07:23:55+00:00"
    },
    {
        "type": "post",
        "url": "yogada-math-noida-uttar-pradesh",
        "last_updated": "2024-09-24T07:25:22+00:00"
    },
    {
        "type": "post",
        "url": "yogada-satsang-kolkata-west-bengal",
        "last_updated": "2024-09-24T07:26:46+00:00"
    },
    {
        "type": "post",
        "url": "skiing-in-auli",
        "last_updated": "2024-10-09T11:52:31+00:00"
    },
    {
        "type": "post",
        "url": "skiing-in-gulmarg",
        "last_updated": "2024-09-24T10:08:20+00:00"
    },
    {
        "type": "post",
        "url": "parmarth-niketan-ashram-rishikesh-uttarakhand",
        "last_updated": "2024-09-24T10:40:50+00:00"
    },
    {
        "type": "post",
        "url": "acharya-prashant",
        "last_updated": "2024-09-24T11:22:02+00:00"
    },
    {
        "type": "post",
        "url": "gurudev-sri-sri-ravishankar",
        "last_updated": "2024-09-24T11:23:24+00:00"
    },
    {
        "type": "post",
        "url": "jaggi-vasudev-sadhguru",
        "last_updated": "2024-09-24T11:24:55+00:00"
    },
    {
        "type": "post",
        "url": "sri-madhukar-nath",
        "last_updated": "2024-09-24T11:26:56+00:00"
    },
    {
        "type": "post",
        "url": "bhrigu-lake-trek-himachal-pradesh",
        "last_updated": "2024-09-25T11:33:46+00:00"
    },
    {
        "type": "post",
        "url": "munnar-kerala",
        "last_updated": "2024-09-26T11:28:23+00:00"
    },
    {
        "type": "post",
        "url": "bandhavgarh-national-park",
        "last_updated": "2024-10-01T09:36:20+00:00"
    },
    {
        "type": "post",
        "url": "gir-national-park-gujarat",
        "last_updated": "2024-10-01T09:38:10+00:00"
    },
    {
        "type": "post",
        "url": "periyar-national-park-kerala",
        "last_updated": "2024-10-01T09:39:57+00:00"
    },
    {
        "type": "post",
        "url": "hemis-national-park",
        "last_updated": "2024-11-01T06:14:16+00:00"
    },
    {
        "type": "post",
        "url": "sundarban-national-park-west-bengal",
        "last_updated": "2024-11-01T06:14:58+00:00"
    },
    {
        "type": "post",
        "url": "auli-joshimath-uttarakhand",
        "last_updated": "2024-10-09T10:32:49+00:00"
    },
    {
        "type": "post",
        "url": "best-places-to-visit-kerala",
        "last_updated": "2024-10-14T03:35:46+00:00"
    },
    {
        "type": "post",
        "url": "kainchi-dham-neem-karauli-ashram-uttarakhand",
        "last_updated": "2024-10-25T10:52:33+00:00"
    },
    {
        "type": "post",
        "url": "ghodakhal-bell-temple-uttarakhand",
        "last_updated": "2024-11-12T16:11:05+00:00"
    },
    {
        "type": "post",
        "url": "brahmatal-trek-uttarakhand",
        "last_updated": "2024-11-30T11:53:44+00:00"
    },
    {
        "type": "post",
        "url": "dayara-bugyal-trek-uttarakhand",
        "last_updated": "2024-11-13T11:19:13+00:00"
    },
    {
        "type": "post",
        "url": "har-ki-dun-trek-uttarakhand",
        "last_updated": "2024-11-04T11:02:48+00:00"
    },
    {
        "type": "post",
        "url": "kuari-pass-pangarchulla-trek-uttarakhand",
        "last_updated": "2024-11-04T11:04:50+00:00"
    },
    {
        "type": "post",
        "url": "rajasthan-best-places-to-visit",
        "last_updated": "2024-12-03T04:52:41+00:00"
    },
    {
        "type": "post",
        "url": "darjeeling-north-east-india",
        "last_updated": "2024-12-07T06:13:46+00:00"
    },
    {
        "type": "post",
        "url": "pindari-glacier-trek-uttarakhand",
        "last_updated": "2024-12-17T12:35:42+00:00"
    },
    {
        "type": "category",
        "url": "trekking",
        "last_updated": "2024-05-18T05:02:07+00:00"
    },
    {
        "type": "category",
        "url": "heritage",
        "last_updated": "2024-07-16T12:07:57+00:00"
    },
    {
        "type": "category",
        "url": "pilgrimages",
        "last_updated": "2024-08-25T02:31:49+00:00"
    },
    {
        "type": "category",
        "url": "beaches-and-more",
        "last_updated": "2024-09-30T09:35:27+00:00"
    },
    {
        "type": "category",
        "url": "adventure",
        "last_updated": "2024-10-01T07:23:02+00:00"
    },
    {
        "type": "category",
        "url": "spirituality",
        "last_updated": "2024-09-24T11:33:32+00:00"
    },
    {
        "type": "category",
        "url": "art-and-culture-india",
        "last_updated": "2024-09-24T14:59:47+00:00"
    },
    {
        "type": "category",
        "url": "festivals-in-india",
        "last_updated": "2024-09-24T15:00:39+00:00"
    },
    {
        "type": "category",
        "url": "food-culture-in-india",
        "last_updated": "2024-09-24T15:01:26+00:00"
    },
    {
        "type": "category",
        "url": "colorful-traditions-in-india",
        "last_updated": "2024-09-24T15:05:23+00:00"
    },
    {
        "type": "category",
        "url": "religions-and-faiths-in-india",
        "last_updated": "2024-09-24T15:06:18+00:00"
    },
    {
        "type": "category",
        "url": "science-and-technology-india",
        "last_updated": "2024-09-24T15:08:25+00:00"
    }
]