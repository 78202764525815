import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ContextProvider } from './Context/Context';

// Select the root element
const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  // If there are child nodes (SSR scenario), hydrate the app
  ReactDOM.hydrateRoot(
    rootElement,
    <React.StrictMode>
      <BrowserRouter>
        <ContextProvider>
          <App />
        </ContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
} else {
  // If no child nodes (CSR scenario), create root and render the app
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <ContextProvider>
          <App />
        </ContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

// Performance measurement (optional)
reportWebVitals();
